<template>
  <div class="p-dataview-layout-options p-selectbutton p-buttonset">
    <button :class="buttonTableClass" @click="changeLayout('table')" type="button">
      <icon data="@icon/list.svg" width="1.5rem" height="1.5rem" />
    </button>
    <button :class="buttonGridClass" @click="changeLayout('grid')" type="button">
      <icon :fill="false" data="@icon/grid.svg" width="1.5rem" height="1.5rem" />
    </button>
  </div>
</template>

<script>
export default {
  name: "DataViewLayoutOptions",
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
  },
  computed: {
    buttonTableClass() {
      return [
        "p-button",
        {
          "p-highlight": this.modelValue === "table",
          "p-disabled": this.$attrs.disabled,
        },
      ];
    },
    buttonGridClass() {
      return [
        "p-button",
        {
          "p-highlight": this.modelValue === "grid",
          "p-disabled": this.$attrs.disabled,
        },
      ];
    },
  },
  methods: {
    changeLayout(layout) {
      this.$emit("update:modelValue", layout);
    },
  },
};
</script>
<style scoped lang="scss">
.p-dataview-layout-options.p-buttonset {
  background: var(--element-bg);
  display: flex;
  justify-content: space-between;
  padding: 2px;

  .p-button {
    background: transparent;
    padding: 0.7rem 1rem;
    border: none;
    color: var(--text-color-alpha-80);

    &.p-highlight {
      color: var(--primary-color);
      background: var(--background);
    }
  }
}
</style>
